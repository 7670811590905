import React, { useEffect } from "react"

import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

const ParentLogin = () => {
  useEffect(() => {
    const ParentLoginWidgetWrapper = {
      LoadParentLoginWidget: function () {
        window.jQuery.ajax({
          url:
            "https://api.discoverchildcare.co.nz/api/Portal?token=0c048b5e-1e18-414c-a3da-3add164f2496",
          success: function (data) {
            window.jQuery("#formWidget").css({ visibility: "hidden" })
            window.jQuery("#formWidget").html(null)
            window.jQuery("#formWidget").html(data)
            window.setTimeout(function () {
              window.jQuery("#formWidget").css({ visibility: "visible" })
            }, 500)
          },
          error: function (xhr, ajaxOptions, thrownError) {
            window
              .jQuery("#formWidget")
              .html(
                "There was an error of type " +
                  xhr.status +
                  " while load Enrollment data "
              )
          },
        })
      },
    }

    var interval = setInterval(function () {
      window.jQuery &&
        window.jQuery(document).ready(function () {
          clearInterval(interval)
          ParentLoginWidgetWrapper.LoadParentLoginWidget()
        })
    }, 300)
  }, [])
  return (
    <>
      <SEO title="Kidd Inn - Parent Login" />

      <CenterBreadCrumbs
        centerName="Kidd Inn"
        centerUrl="/kiddinn/"
        subPageName="Parent Login"
      />

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div
              id="main-page-content"
              className="section-container main-page-content clearfix"
            >
              <div className="section-content">
                <h1 className="page_title">Parent Login</h1>
                <div id="content">
                  <div id="formWidget"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParentLogin
